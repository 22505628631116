import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import BodyCopy from '../components/atoms/body-copy'
import Lists from '../components/atoms/lists'


const TermsServicesPage = () => (
  <Layout hideSecureForm>
    <SEO title='Terms &amp; Services' />
    <Heading 
        title='Terms &amp; Services'
        weight='light'
    />
    <BodyCopy
      copy={
        'All program details will vary based upon your specific request and are determined independently by each Partner Advertiser with whom you are matched. There is a possibility that you may not be matched with a Partner Advertiser in this particular Survey. Specific details about products available from Survey advertisers are available on their respective websites. Not all programs are available in all states. SunshineDebtSolutions.com only participates in matching you with Partner Advertiser in the debt relief process.',
        'Please review these Terms of Use, which govern your use of SunshineDebtSolutions.com (the “Site”, “we”, “us”, or “our”). Your use of the Site constitutes your agreement to follow and be bound by these Terms of Use. We reserve the right to update or modify this Agreement at any time without prior notice. For this reason, we encourage you to review the Agreement whenever you use any of the Sites. If you do not agree to these terms, please do not use the Site.'
      }
    />

    <Heading 
        title='Site Modification or Suspension'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'We reserve the right, at any time in our sole discretion, to modify, suspend or discontinue the sites or any service, content, feature or product offered through the Site, with or without notice. You agree that we shall not be liable to any third party for any modification, suspension or discontinuance of the Site, or any service, content, feature or product offered through the Site.'
      }
    />

    <Heading 
        title='Privacy'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'By using the Site you agree to the terms of our Privacy Policy.'
      }
    />

    <Heading 
        title='Site Contents'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'Unless otherwise noted, the Site and all materials on the site, including text, images, illustrations, designs, icons, photographs, video clips and other materials, and the copyrights, trademarks, trade dress or other intellectual property in such materials (collectively, the “Content”), are owned, controlled or licensed by SunshineDebtSolutions or its parent company.'
      }
    />


    <Heading 
        title='Use of the Site'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'You may not use or register with the Site if you are under eighteen (18) years of age. You agree that the information that you provide to us upon registration, and at all other times will be true, accurate, current and complete. By using the Site and/or accessing the services, you represent and warrant that you have the right, authority and capacity to enter into these Terms of Use and to abide by all of the terms and conditions set forth in these Terms of Use. This Site is administered in the United States and intended for U.S. users; any use outside of the U.S. is at your own risk and you are responsible for compliance with any local laws applicable to your use of the Site or access to the Services.'
      }
    />


    <Heading 
        title='ELECTRONIC COMMUNICATIONS/NOTICES AND INFORMATION DELIVERED ELECTRONICALLY'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'SunshineDebtSolutions and Providers may choose to electronically deliver all information related to its services and your requests. SunshineDebtSolutions and Providers electronic communications to you may transmit or convey information about action taken on your request, portions of your request that may be incomplete or require additional explanation, any notices required under applicable law, which may include any Federal Truth-in-Lending disclosures, State disclosures, other terms, conditions and documents, and the privacy policies of SunshineDebtSolutions and Providers. You agree to receive all current and future notices, disclosures, communications and information, and to do business electronically with SunshineDebtSolutions and Providers. This means that SunshineDebtSolutions and Providers may communicate with you by sending a message to the email address you provided or at another address that may be associated with you that we receive from Providers or other parties. You agree that you meet the below technical requirements and are able to access and retain copies of notices and information sent or made available electronically.'
      }
    />


    <Heading 
        title='License and Access'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'Subject to your compliance with these Terms of Use, SunshineDebtSolutions grants you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make personal and non-commercial use of the SunshineDebtSolutions site and the service provided thereon. This license does not include any resale or commercial use of any SunshineDebtSolutions service or Content, any collection and use of any class or class provider listings, descriptions, or prices; any downloading or copying of account information for the benefit of another merchant, any derivative use of this site or its Content; or any use of robots, data mining, or similar data gathering and extraction tools. The Site or any portion of it may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of SunshineDebtSolutions. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including text, images of page layout, or form) of SunshineDebtSolutions and our affiliates without express written consent. You may not use any meta tags or any other “hidden text” utilizing the SunshineDebtSolutions Name or trademarks without the express written consent of SunshineDebtSolutions. All right not expressly granted to you in these Terms of Use are reserved and retained SunshineDebtSolutions or its affiliates, licensors, publishers, rights holders or other content providers. Any unauthorized use of the Site or services provided thereon terminates the permission or license to access and use the site.'
      }
    />


    <Heading 
        title='Intellectual Property'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'This Site and the Content available on the Site are the property of SunshineDebtSolutions or its affiliates and licensors, and are protected by copyright, trademark and other intellectual property laws. Subject to these Terms of Use, SunshineDebtSolutions grants you a non-exclusive non-transferable license to use the Site solely for your personal non-commercial use. You may not use the Site or the Content available on the Site in a manner that constitutes an infringement of our rights or that has not been authorized by us. More specifically, unless explicitly authorized in these Terms of Use or by the owner of the Content, you may not modify, copy, reproduce, republish, upload, post, transmit, translate, sell, create derivative works, exploit, perform, display or distribute in any manner or medium (including by e-mail or other electronic means) any material from the Site. You may, however, from time to time, download or print one copy of individual pages of the Site for your personal, non-commercial use, provided that you keep intact all copyright and other proprietary notices.'
      }
    />

    <Heading 
        title='Services'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'SunshineDebtSolutions is a marketing lead generator. SunshineDebtSolutions services are only administrative. You may have to complete an application with a Provider before they will extend an unconditional offer. SunshineDebtSolutions is paid a marketing lead generation fee by providers for the services provided.',
        'You should rely on your own judgment in deciding which available product, terms and Provider best suits your needs and financial means. The Provider is solely responsible for its services to you, and you agree that SunshineDebtSolutions shall not be liable for any damages or costs of any type arising out of or in any way connected with your use of such services. You understand that Providers may keep your request information and any other information provided by SunshineDebtSolutions or received by them in the processing of your  request, whether or not you are qualified with them or if you make an agreement with them. You agree to notify any particular Provider directly if you no longer want to receive communications from them.',
        'The Websites and the services provided by SunshineDebtSolutions may only be made available to residents of, or secured by real property located in, states where Providers are licensed or authorized. Providers are not attempting to find customers outside of their authorized states or country by participating in and offering their products on the Websites. SunshineDebtSolutions Providers expressly reserve the right to discontinue, suspend or terminate the offering of any product in any specific state through the Websites at any time, without prior notice.',
        'SunshineDebtSolutions does not guarantee acceptance into any particular program or specific terms or conditions with any Provider;  approval standards are established and maintained solely by individual Providers. A Provider’s offer may be subject to market conditions, approval and qualification. The fees actually provided by Providers may be higher or lower depending on your complete profile and financial circumstances. You might not be matched with the Provider making any specific offer. To help the government fight identity theft, the funding of terrorism and money laundering activities, and to help attempt to verify your identity, Providers may obtain, verify and record information that identifies each person who opens an account with us and them. Providers may ask for your name, address, telephone number and other important information that will allow them to properly identify you. During the application process, they may also ask to see your driver’s license or other identifying documentation to further properly identify you.'
      }
    />


    <Heading 
        title='Reviews, Comments, Communications and Other Content'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'In the event that consumers may submit comments; send e-mail communications; and submit suggestions, ideas, comments, questions, or other information to the Site, consumers may do so, so long as the content is not illegal, threatening, defamatory, obscene, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, commercial solicitation, political campaigning, mass mailings, chain letters, or any form of “spam.” You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content. SunshineDebtSolutions reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content. SunshineDebtSolutions has the right but not the obligation to oversee and edit or remove any activity or content. SunshineDebtSolutions claims no responsibility and assumes no liability for any content posted by you or any third party.'
      }
    />


    <Heading 
        title='Third Party Content and Links to Other Websites'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'Third parties not affiliated with SunshineDebtSolutions may offer services or products on this site. We do not warrant or assume any responsibility or liability for the actions, offers or content of third parties who may have advertisements or links on our site. SunshineDebtSolutions does not endorse, has no control over, and is not responsible in any manner for the terms, conditions, fulfillment or performance of such third parties’ advertisements or offers, the collection of information from you by any such third parties, the use of your information by any such third parties, or the use, operation or availability of websites owned or operated by, or on behalf of, any such third parties. You should carefully review their privacy statements and other conditions of use. You further acknowledge and agree that SunshineDebtSolutions will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any third party content or links, products, goods or services available on or through any such websites.'
      }
    />



    <Heading 
        title='Indemnification'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'YOU AGREE TO INDEMNIFY SunshineDebtSolutions, INCLUDING SunshineDebtSolutions’S PARTNERS, OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, PARENTS, SUBSIDIARIES, AFFILIATES, SUCCESSORS, ADVERTISERS, AND THIRD PARTY SERVICE PROVIDERS (COLLECTIVELY, SunshineDebtSolutions PARTIES’), AND HOLD THEM EACH HARMLESS FROM ANY AND ALL CLAIMS OR DEMANDS, INCLUDING REASONABLE ATTORNEY’S FEES AND COSTS, MADE BY ANY THIRD PARTY DUE TO OR ARISING FROM YOUR USE OF THE SERVICE, YOUR VIOLATION OF THESE TERMS AND CONDITIONS OR YOUR VIOLATION OF ANY RIGHTS OF A THIRD PARTY.'
      }
    />


    <Heading 
        title='Release'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'YOU HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE SunshineDebtSolutions AND SunshineDebtSolutions PARTIES AND EACH OF THEIR RESPECTIVE AGENTS, DIRECTORS, OFFICERS, EMPLOYEES, AND ALL OTHER RELATED PERSONS OR ENTITIES FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER KNOWN OR UNKNOWN, WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH YOUR USE OF THE SERVICE.'
      }
    />

    <Heading 
        title='Disclaimer of Warranties and Limitation of Liability'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'THIS SITE IS PROVIDED BY SunshineDebtSolutions ON AN “AS IS” AND “AS AVAILABLE” BASIS. SunshineDebtSolutions MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, SunshineDebtSolutions DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. SunshineDebtSolutions OR ITS PARTIES MAKES NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS; (b) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; OR (d) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL MEET YOUR EXPECTATIONS OR NEEDS. SunshineDebtSolutions DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM SunshineDebtSolutions ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. SunshineDebtSolutions WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.'
      }
    />


    <Heading 
        title='Applicable Law'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'By visiting SunshineDebtSolutions, you agree that the laws of the state of Florida, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and SunshineDebtSolutions or its affiliates.'
      }
    />


    <Heading 
        title='Disputes'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'Any dispute relating in any way to your visit to SunshineDebtSolutions or loans you apply for through SunshineDebtSolutions shall be submitted to confidential arbitration in the State of Florida, except that, to the extent you have in any manner violated or threatened to violate any SunshineDebtSolutions intellectual property rights, SunshineDebtSolutions may seek injunctive or other appropriate relief in any state or federal court in the state of Florida and you consent to exclusive jurisdiction and venue in such courts. Arbitration under this agreement shall be conducted under the rules then prevailing of the American Arbitration Association. The arbitrator’s award shall be binding and may be entered as a judgment in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other party subject to this Agreement, whether through class arbitration proceedings or otherwise.'
      }
    />


    <Heading 
        title='Contact Information'
        titleType='h4'
        weight='light'
        align='left'
    />

    <BodyCopy
      copy={
        'If you have any questions or comments relating to our website, these Terms of Use or our Privacy Policy, please contact us by email.'
      }
    />

  </Layout>
)

export default TermsServicesPage
